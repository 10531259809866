.main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  @media #{$xs-layout} {
    flex-wrap: wrap;
  }
  .sides {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    @media #{$xs-layout} {
      width:100%;
    }
    .box {
      margin: 1rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      width: 20rem;
      @media #{$xs-layout} {
        width:auto;
      }
      height: 20rem;
      background-color: $theme-color;
    }
  }
  .middle {
    display: flex;
    flex: 1;
    margin-top: 2rem;
    margin-bottom: 2rem;
    
    .box {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      width: 100%;
      height: inherit;
      background-color: $theme-color;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      @media #{$xs-layout} {
        align-items: center;
        justify-content: center;
      }
      padding: 3rem;
      a {
        font-size: 16px;
        color: #fff;
        display: inline-block;
        border: 1px solid $theme-color;
        text-transform: uppercase;
        line-height: 1;
        padding: 19px 50px 19px;
        background-color: $theme-color;
        &:hover {
            color: #fff;
            border: 1px solid $theme-color;

        }
    }
    }
  }
}
