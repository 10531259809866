/*------ 10. Suscribe style  ------*/
.subscribe-area{
  background-color: $theme-color-light;
}
.subscribe-style-2 {
  @media #{$xs-layout} {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  h2 {
    font-size: 48px;
    color: $secundary-color;
    margin: 0 0 23px;
    @media #{$xs-layout} {
      font-size: 30px;
      margin: 0 0 12px;
    }
  }
  p {
    font-size: 16px;
    color: $secundary-color;
  }
  .subscribe-form-2 {
    input {
      background: transparent;
      border: none;
      height: 45px;
      padding: 2px 50px 2px 0;
      font-size: 14px;
      color: #fff;
      border-bottom: 2px solid #fff;
      &::placeholder{
        color:#fff;
      }
      @media #{$xs-layout} {
        padding-left: 0rem;
      }
    }
    .mc-news {
      display: none;
    }
    .mc-form {
      display: flex;
      position: relative;
      .dv-mob {
        @media #{$xs-layout} {
          padding-left: 0px !important;
          padding-top: 0.5rem;
        }
      }

      @media #{$xs-layout} {
        display: flex;
        flex-direction: column;
      }
    }
    .clear-2 {
      //background: rgba(0, 0, 0, 0) url("../../assets/img/icon-img/suscribe.png") no-repeat scroll right 0 center;
      bottom: 0;
      display: inline-block;
      position: absolute;
      right: 0;
      button {
        background: none;
        border: none;
        height: 45px;
        padding: 0;
        font-size: 30px;
        color: #fff;
        transition: 0.3s;
        @media #{$xs-layout} {
          font-size: 20px;
        }
        &:hover {
          color:$theme-color;
        }
      }
    }
  }
}

.subscribe-area-3.pb-100 {
  @media #{$xs-layout} {
    padding-bottom: 60px !important;
  }
}
.subscribe-style-3 {
  h2 {
    font-size: 48px;
    color: #010101;
    margin: 0 0 23px;
    @media #{$xs-layout} {
      font-size: 35px;
      margin: 0 0 12px;
    }
    @media #{$md-layout} {
      font-size: 40px;
    }
  }
  p {
    font-size: 16px;
    color: #696969;
    margin: 0;
    line-height: 1.5;
  }
  .subscribe-form-3 {
    input {
      background: transparent;
      border: none;
      border-bottom: 2px solid #e2e2e2;
      text-align: center;
      color: #333;
      height: 46px;
    }
    .mc-news {
      display: none;
    }
    .clear-3 {
      margin-top: 40px;
      input,
      button {
        width: auto;
        height: auto;
        color: #fff;
        background: none;
        border: none;
        background-color: #070508;
        line-height: 1;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 17px 55px;
        border-radius: 5px;
        &:hover {
          background-color: $theme-color;
        }
      }
      &.red-subscribe {
        input,
        button {
          background-color: #df262b;
          &:hover {
            background-color: $theme-color;
          }
        }
      }
      &.green-subscribe {
        input,
        button {
          background-color: #6eab49;
          &:hover {
            background-color: $theme-color;
          }
        }
      }
      &.dark-red-subscribe {
        input,
        button {
          background-color: #c61a32;
          border-radius: 50px;
          &:hover {
            background-color: $theme-color;
          }
        }
      }
      &.red-2-subscribe {
        input,
        button {
          background-color: #e90042;
          &:hover {
            background-color: $theme-color;
          }
        }
      }
      &.pink-subscribe {
        input,
        button {
          background-color: #ed59a0;
          &:hover {
            background-color: $theme-color;
          }
        }
      }
    }
  }
}
